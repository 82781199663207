var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-content',[_c('v-container',{attrs:{"grid-list-lg":"","fluid":""}},[_c('v-layout',{attrs:{"wrap":"","align-start":"","justify-start":"","fill-height":""}},[_c('v-flex',{attrs:{"xs12":"","lg12":"","xl12":""}},[_c('app-title',[_c('template',{slot:"title"},[_vm._v("\n            Hay\n            "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.ordersAll.length))]),_vm._v("\n            pedidos sin boleta o factura\n          ")]),_c('template',{slot:"subtitle"},[_vm._v("\n            Solo aparecen los pedidos sin boleta o factura\n          ")]),_c('template',{slot:"button"},[_c('div',{staticStyle:{"width":"400px","float":"right"}},[_c('v-select',{attrs:{"items":_vm.schedules,"item-text":function (e) {
                    return e.schedule
                  },"label":"Filtar por horarios","multiple":"","chips":"","attach":"","persistent-hint":"","solo":""},on:{"change":_vm.changeSchedule},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                  var item = ref.item;
                  var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',{domProps:{"innerHTML":_vm._s(item)}})]):_vm._e(),(index === 1)?_c('span',{staticClass:"caption"},[_vm._v(" (+"+_vm._s(_vm.selectedSchedules.length - 1)+" horarios) ")]):_vm._e()]}}]),model:{value:(_vm.selectedSchedules),callback:function ($$v) {_vm.selectedSchedules=$$v},expression:"selectedSchedules"}})],1)])],2)],1),_c('v-flex',{attrs:{"xs12":"","lg12":"","xl12":""}},[_c('v-card',{staticClass:"elevation-2"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"select-all":"","item-key":"id","headers":_vm.headers,"items":_vm.orders,"pagination":_vm.pagination,"rows-per-page-items":_vm.rowsPerPage,"loading":_vm.loading,"rows-per-page-text":"Pedidos por pagina","no-data-text":"No hay pedidos para generar boleta/factura"},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('tr',{attrs:{"active":props.selected}},[_c('td',{on:{"click":function($event){props.selected = !props.selected}}},[(!props.item.generate && props.item.send === undefined)?[_c('v-checkbox',{attrs:{"color":"primary","hide-details":""},model:{value:(props.selected),callback:function ($$v) {_vm.$set(props, "selected", $$v)},expression:"props.selected"}})]:(!props.item.generate && props.item.send === 1)?[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"accent"}})]:(props.item.generate && props.item.send === 2)?[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("check_circle")])]:(props.item.generate && props.item.send === 3)?[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("error")])]:_vm._e()],2),_c('td',[_c('v-btn',{attrs:{"color":"info","to":{
                      name: 'orderIndex',
                      params: { id: props.item.id }
                    },"target":"_blank","small":""}},[_vm._v(_vm._s(props.item.id))])],1),_c('td',{staticClass:"text-xs-left",on:{"click":function($event){props.selected = !props.selected}}},[_vm._v("\n                  "+_vm._s(_vm._f("moment")(props.item.date,'DD - MMMM - YYYY'))+"\n                ")]),_c('td',{staticClass:"text-xs-left",on:{"click":function($event){props.selected = !props.selected}}},[_vm._v("\n                  "+_vm._s(_vm._f("moment")(props.item.date_shipping,'DD - MMMM - YYYY'))+"\n                ")]),_c('td',{staticClass:"text-xs-left",domProps:{"innerHTML":_vm._s(props.item.status.name)},on:{"click":function($event){props.selected = !props.selected}}}),_c('td',{staticClass:"text-xs-left",domProps:{"innerHTML":_vm._s(props.item.payment)},on:{"click":function($event){props.selected = !props.selected}}}),_c('td',{staticClass:"text-xs-left",on:{"click":function($event){props.selected = !props.selected}}},[_vm._v("\n                  "+_vm._s(_vm._f("toPrice")(props.item.total))+"\n                ")]),_c('td',{staticClass:"text-xs-left",domProps:{"innerHTML":_vm._s(props.item.message)},on:{"click":function($event){props.selected = !props.selected}}}),_c('td',{staticClass:"text-xs-left",on:{"click":function($event){props.selected = !props.selected}}},[(props.item.type)?_c('span',[_vm._v("Boleta")]):_c('span',[_vm._v("Factura")])])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)],1),_c('v-btn',{attrs:{"loading":_vm.loadingBtnSend,"fab":"","dark":"","fixed":"","bottom":"","right":"","color":"accent"},on:{"click":_vm.sendPrints}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("send")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }