<template>
  <v-content>
    <v-container grid-list-lg fluid>
      <v-layout wrap align-start justify-start fill-height>
        <v-flex xs12 lg12 xl12>
          <app-title>
            <template slot="title">
              Hay
              <span class="font-weight-bold">{{ ordersAll.length }}</span>
              pedidos sin boleta o factura
            </template>
            <template slot="subtitle">
              Solo aparecen los pedidos sin boleta o factura
            </template>
            <template slot="button">
              <div style="width:400px;float:right;">
                <v-select
                  v-model="selectedSchedules"
                  :items="schedules"
                  :item-text="
                    e => {
                      return e.schedule
                    }
                  "
                  label="Filtar por horarios"
                  multiple
                  chips
                  attach
                  persistent-hint
                  solo
                  @change="changeSchedule"
                >
                  <template slot="selection" slot-scope="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span v-html="item"></span>
                    </v-chip>
                    <span v-if="index === 1" class="caption"> (+{{ selectedSchedules.length - 1 }} horarios) </span>
                  </template>
                </v-select>
              </div>
            </template>
          </app-title>
        </v-flex>
        <v-flex xs12 lg12 xl12>
          <v-card class="elevation-2">
            <v-data-table
              v-model="selected"
              select-all
              item-key="id"
              :headers="headers"
              :items="orders"
              :pagination.sync="pagination"
              :rows-per-page-items="rowsPerPage"
              :loading="loading"
              rows-per-page-text="Pedidos por pagina"
              no-data-text="No hay pedidos para generar boleta/factura"
              class="elevation-0"
            >
              <template slot="items" slot-scope="props">
                <tr :active="props.selected">
                  <td @click="props.selected = !props.selected">
                    <template v-if="!props.item.generate && props.item.send === undefined">
                      <v-checkbox v-model="props.selected" color="primary" hide-details />
                    </template>
                    <template v-else-if="!props.item.generate && props.item.send === 1">
                      <v-progress-circular indeterminate color="accent" />
                    </template>
                    <template v-else-if="props.item.generate && props.item.send === 2">
                      <v-icon color="success">check_circle</v-icon>
                    </template>
                    <template v-else-if="props.item.generate && props.item.send === 3">
                      <v-icon color="error">error</v-icon>
                    </template>
                  </td>
                  <td>
                    <v-btn
                      color="info"
                      :to="{
                        name: 'orderIndex',
                        params: { id: props.item.id }
                      }"
                      target="_blank"
                      small
                      >{{ props.item.id }}</v-btn
                    >
                  </td>
                  <td class="text-xs-left" @click="props.selected = !props.selected">
                    {{ props.item.date | moment('DD - MMMM - YYYY') }}
                  </td>
                  <td class="text-xs-left" @click="props.selected = !props.selected">
                    {{ props.item.date_shipping | moment('DD - MMMM - YYYY') }}
                  </td>
                  <td class="text-xs-left" @click="props.selected = !props.selected" v-html="props.item.status.name" />
                  <td class="text-xs-left" @click="props.selected = !props.selected" v-html="props.item.payment" />
                  <td class="text-xs-left" @click="props.selected = !props.selected">
                    {{ props.item.total | toPrice }}
                  </td>
                  <td class="text-xs-left" @click="props.selected = !props.selected" v-html="props.item.message" />
                  <td class="text-xs-left" @click="props.selected = !props.selected">
                    <span v-if="props.item.type">Boleta</span>
                    <span v-else>Factura</span>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-btn :loading="loadingBtnSend" fab dark fixed bottom right color="accent" @click="sendPrints">
      <v-icon dark>send</v-icon>
    </v-btn>
  </v-content>
</template>

<script>
import appTitle from './useful/title.vue'
import { GET_ORDER_DOCUMENT, GENERATE_SII } from '../config'

export default {
  name: 'DocumentOrders',
  components: { appTitle },
  metaInfo() {
    return {
      title: this.title
    }
  },
  data: () => ({
    selectedSchedules: [],
    title: 'Pedidos sin boleta o factura',
    loading: false,
    loadingBtnSend: false,
    selected: [],
    ordersAll: [],
    orders: [],
    pagination: { rowsPerPage: 50 },
    rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
    headers: [
      {
        text: 'Pedido',
        align: 'left',
        sortable: false,
        value: 'order'
      },
      {
        text: 'Fecha de compra',
        align: 'left',
        sortable: false,
        value: 'date'
      },
      {
        text: 'Fecha de entrega',
        align: 'left',
        sortable: false,
        value: 'date_shipping'
      },
      {
        text: 'Estado',
        align: 'left',
        sortable: false,
        value: 'status'
      },
      {
        text: 'Medio de pago',
        align: 'left',
        sortable: false,
        value: 'payment'
      },
      {
        text: 'Total',
        align: 'left',
        sortable: false,
        value: 'total'
      },
      {
        text: 'Mensaje',
        align: 'left',
        sortable: false,
        value: 'message'
      },
      {
        text: 'Tipo de documento',
        align: 'left',
        sortable: false,
        value: 'type'
      }
    ]
  }),
  computed: {
    general() {
      return this.$store.getters.getGeneral
    },
    massive() {
      return this.$store.getters.getMassive
    },
    schedules() {
      const se = []
      const ss = this._.groupBy(this.ordersAll, 'schedule')
      this._.forEach(ss, function(v) {
        console.log(v[0].schedule)
        se.push(v[0].schedule)
      })

      return se
    }
  },
  watch: {
    massive(e) {
      if (e.send === 1) {
        setTimeout(() => {
          if (e.key !== undefined) {
            this.$http.get(`${GENERATE_SII}${this.selected[e.key].code}`).then(res => {
              if (res.data.error === 1) {
                this.selected[e.key].send = 3
                this.selected[e.key].generate = true
              } else {
                this.selected[e.key].send = 2
                this.selected[e.key].generate = true
              }
              this.sendFirst()
            })
          } else {
            this.loadingBtnSend = false
            this.selected = []
          }
        }, 2000)
      } else {
        this.selected = []
      }
    }
  },
  mounted() {
    this.getOrdersDocument()
  },
  methods: {
    async getOrdersDocument() {
      this.loading = true
      const res = await this.$http.get(GET_ORDER_DOCUMENT, {
        date: this.general.date
      })
      this.loading = false
      if (res.data.length > 0) {
        this.ordersAll = res.data
        this.orders = res.data
        this.title = `Hay ${this.orders.length} pedidos sin boleta o factura`
      }
    },
    sendPrints() {
      if (this.selected.length > 0) {
        this.$store.dispatch('setConfirm', {
          active: true,
          title: '¿Imprimir estos pedidos?',
          description: '',
          action: this.sendPrintsAction
        })
      } else {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Debe seleccionar pedidos para imprimir'
        })
      }
    },
    sendPrintsAction() {
      this.$store.dispatch('setConfirm', { active: false })
      this.loadingBtnSend = true
      this._.forEach(this.selected, (item, index) => {
        this.selected[index].send = 1
      })
      this.sendFirst()
    },
    sendFirst() {
      const key = this._.findKey(this.selected, { send: 1 })
      this.$store.dispatch('changeMassive', {
        key,
        send: 1
      })
    },
    changeSchedule() {
      const selected = []
      this.selected = []
      this.orders.forEach(e => {
        const k = this._.findIndex(this.selectedSchedules, function(o) {
          return o == e.schedule
        })
        if (k >= 0) {
          selected.push(e)
        }
      })
      this.selected = selected
    }
  }
}
</script>
